module.exports = new Promise(resolve => {
    const remoteUrl = window.location.origin.includes('localhost') ? '' : window.location.origin + '/remotejs/basepages/remoteEntry.js';
    const script = document.createElement('script')
    script.src = remoteUrl
    script.onload = () => {
      const proxy = {
        get: (request) => window.cm_mf_common.get(request),
        init: (arg) => {
          try {
            return window.cm_mf_common.init(arg)
          } catch(e) {
            console.log('remote cm_mf_common already initialized')
          }
        }
      }
      resolve(proxy)
    }

    document.head.appendChild(script);
  });